import React from "react";
import './footer.css'

function Footer() {
  return(
    <div className="footer">
    <p style={{alignContent: "center"}}>© 2021 Alina Maliuk</p>
    </div>
  )
}

export default Footer