import React, {useContext, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import LoginForm from "../components/common/LoginForm";
import RestaurantCard from "../restaurants page/RestaurantCard";
import CityCard from "../restaurants page/CityCard";
import CssBaseline from '@mui/material/CssBaseline';
import { UserContext } from "../App";
import { Typography } from "@mui/material";
import { Container } from '@material-ui/core';
import LoadingAnimation from "../components/common/LoadingAnimation";

function LoggedIn({
  selectedCuisine, selectedFood, budget,
  setSelectedCuisine, setSelectedFood, setBudget,
  topCity, setTopCity, setUser
}) {
  const [destinations, setDestinations] = useState ()
  const [destinationsIsLoaded, setDestinationsIsLoaded] = useState (false)
  const [selectedDestination, setSelectedDestination] = useState ()
  const [matchingRestaurants, setMatchingRestaurants] = useState([]);
  const [bookmarkedRestaurants, setBookmarkedRestaurants] = useState([]);

  const {user, jwt} = useContext(UserContext)

  useEffect(() => {
    if (user) {
      setDestinations(user.destinations)
      setDestinationsIsLoaded(true)
    }
  }, [user])

  useEffect(() => {
    if (user) {
      let tempUser = user
      tempUser.destinations = destinations
      setUser (tempUser)
    }
  }, [destinations])

  useEffect(() => {
    if (user) {
      setBookmarkedRestaurants (user.bookmarks)
    } else
    {
      if (jwt !== null) {
        console.log ("here")
        fetch(`${process.env.REACT_APP_API_URL}/getUser`,
          { headers: {Authorization: jwt}}
          )
          .then((apiResponse) => {
            if ((apiResponse.status === 403) || (apiResponse.status === 500) )
              {
                localStorage.removeItem("jwt")
                return
              }
            return apiResponse.json()
          })
          .then(data => {
            setUser (data)
            setMatchingRestaurants([])
          })
          .catch(alert);
      }
    }
  }, []);

  useEffect(() => {
    if (user) setBookmarkedRestaurants (user.bookmarks)
  }, [user]);

  useEffect(() => {
    if (user && topCity && selectedCuisine) {
      let data = {
        cuisine: selectedCuisine,
        food: selectedFood,
        budget: budget,
        city: topCity.name
      }
      let tmpJWT = localStorage.getItem("jwt")
      fetch(`${process.env.REACT_APP_API_URL}/addDestination`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: tmpJWT
        },
        body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((responseData) => {
        setDestinations(responseData.destinations)
        setDestinationsIsLoaded(true)
        setSelectedDestination (data)
        setTopCity(null)
      })
      .catch(alert);
    }
  }, [user, topCity]);

  useEffect(() => {
    if (selectedDestination) {
    const data = {
      cuisine: selectedDestination.cuisine,
      food: selectedDestination.food,
      budget: selectedDestination.budget,
    };
    fetch(`${process.env.REACT_APP_API_URL}/search/${selectedDestination.city}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(setMatchingRestaurants) 
      .catch(alert);
    }
  }, [selectedDestination]);

  return ( 
    <>
    <Navbar />
    {
       !user ? ( 
        <LoginForm type="login"/>
       ) : ((!setDestinationsIsLoaded) ? 
        (
          <Box>
            <LoadingAnimation /> 
          </Box>
        )
        : 
        (
        <Box>
          <CityCard
            destinations={destinations}
            selectedDestination={selectedDestination}
            setSelectedDestination={setSelectedDestination}
          />
          {selectedDestination ? (
         
            <RestaurantCard
              matchingRestaurants={matchingRestaurants}
              bookmarkedRestaurants={bookmarkedRestaurants}
              setBookmarkedRestaurants={setBookmarkedRestaurants}
              user={user}
              
            />
            
            ) : (
              <Container component="main" sx={{display: "flex", alignContent: "center"}}>
              <CssBaseline />
              <Typography gutterBottom variant="h5" component="div" sx={{width: "100%"}}>Select city to view matching restaurants</Typography>
              </Container>

            )
          }
        </Box>)
       )
    }
    <Footer />
    </>  
  )
}

export default LoggedIn;
